
import React from "react";

import Wavesurfer from "wavesurfer.js";
import '../App.css';
import VideoBackground from "../components/videoBackground";
import MainPageTracks from '../databases/mainPageTracks.json'

import '../Backgrounds.css'

import AudioPlayer from "../components/audioPlayer/AudioPlayer";
import MainHeader from "../components/MainHeader";
import Slider from "../components/Carousel.js";
import './Home.css';

import LoadingPage from "../components/LoadingPage";
import { useState,useRef,useEffect } from "react";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import {IoIosArrowDown} from 'react-icons/io'


const Home = () => {

    useEffect(() => {
        window.scrollTo(0,0)
      }, []);


    // let videoURL = MainPageBackgrounds[1].videoURL


    const [loading, setLoading] = useState(false);
    const [videoIsPlaying,setVideoPlaying] = useState(true);
    const [videoUrl,setVideoUrl] = useState(MainPageTracks[0].videoUrl);
    const [videoMounted,setVideoMounted] = useState(true);
    const [downArrowVisible,setDownArrowVisible] = useState(true);

    useEffect(() => {
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
        // setVideoPlaying(true);
        // setVideoNumber(0)
      }, 1500)
      setTimeout(() => {
        setDownArrowVisible(true)
        // setVideoPlaying(true);
        // setVideoNumber(0)
      }, 5000)

    }, [])

    


    const nodeRef = useRef(null);

    const loadHandler = (event) => {
        setLoading(false);
        
    }


    const onTrackChange = (newTrackDetails) => {
        console.log(newTrackDetails.videoUrl);
        console.log(videoUrl);

        if (newTrackDetails.videoUrl != videoUrl)
        {
            setVideoMounted(false);
            setTimeout(() => {setVideoUrl(newTrackDetails.videoUrl)}, 300)
            setTimeout(() => {setVideoMounted(true)}, 305)
        }
    }

    const handleDragStart = (e) => e.preventDefault();
    return (


        <div >
            <LoadingPage loading={loading}/>
            <div className="portfolioBackground">
            {/* <div className="background"> */}


            <CSSTransition nodeRef={nodeRef} in={videoMounted} timeout={500} classNames="my-node" unmountOnExit>

            
                <div ref={nodeRef}>
                    <VideoBackground videoUrl = {videoUrl} />
                  {/* <OpenDropdown openDropdown={openDropdown}/> */}
                </div>
                
            </CSSTransition>
            
            
            
            
            
            </div>
            <div className="foreground">
            <MainHeader/>
            



                <div className="audioPlayerContainer">
                    <div className="audioPlayerFrame">
                        <AudioPlayer trackArray = {MainPageTracks} folderPath = 'mainPageMusic/' multiTrack = 'true' onTrackChange = {onTrackChange}/>
                    </div>
                    
                </div>

                <div className = "homeBackground">
                    
                    <div className="homeContentContainer">

                    <CSSTransition nodeRef={nodeRef} in={downArrowVisible} timeout={500} classNames="my-node" unmountOnExit>
                        <IoIosArrowDown className="arrowDown"/>
                    </CSSTransition>
                    
                        <div className="block">


                            <div className = "subheading2"> WILL TURNER COMPOSER</div>
                            <div className="paragraph1">Will Turner is an award winning composer for Film, TV and Games.</div>
                            
                            <div className="aboutMeClickThroughContainer">


                                <Link to='/about' className="button1">
                                <button className="button1">
                                MORE ABOUT WILL
                                </button>
                                </Link>
                            </div>

                        
                        </div>
                        <div className="block" onLoad={loadHandler}>
                            <div className="lineHeading1"> RECENT WORK </div>
                        </div>

                        <Slider/>


                        <div className="solidLine"></div>

                        <Footer/>
                        </div>

                       




                </div>

                
            {/* </div> */}
            </div>
        </div>

    )
}
export default Home;